import { initializeApp, getApps } from 'firebase/app';
import { browserLocalPersistence, browserPopupRedirectResolver, initializeAuth, Auth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = JSON.parse(
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG ??
    '{"apiKey":"AIzaSyD6PAj0eGMi_0qOxYm973C1Qpc_rP3KyO8","authDomain":"dev-auth.pronostix.io","databaseURL":"https://dev-pronostix.firebaseio.com","projectId":"dev-pronostix","storageBucket":"dev-pronostix.appspot.com","messagingSenderId":"762050164419","appId":"1:762050164419:web:0752c53ef8a4b52921d476","measurementId":"G-KZEQD5FP3M"}',
);

const apps = getApps();

export const app = apps.length > 0 ? apps[0] : initializeApp(firebaseConfig);

// why : https://github.com/firebase/firebase-js-sdk/discussions/4510#discussioncomment-400444
//@ts-ignore
const authProvider = app.container.getProvider('auth-exp');
let authy: Auth;
if (authProvider.isInitialized()) {
  authy = authProvider.getImmediate();
} else {
  authy = initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  });
}

export const auth = authy;

export const fn = getFunctions(app, 'europe-west1');
