import { useEffect, useState } from 'react';
export function useObservable(observable) {
  var _useState = useState(),
      state = _useState[0],
      setState = _useState[1];

  useEffect(function () {
    var sub = observable.subscribe(setState);
    return function () {
      return sub.unsubscribe();
    };
  }, [observable]);
  return state;
}