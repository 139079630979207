import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { httpsCallable as httpsCallableFire } from 'firebase/functions';
import { auth, fn } from './firebase';
export function userObs() {
  return new Observable(function (subscriber) {
    var unsubscribe = onIdTokenChanged(auth, function (idToken) {
      return subscriber.next(idToken);
    });
    return {
      unsubscribe: unsubscribe
    };
  });
}
export function authState() {
  return new Observable(function (subscriber) {
    var unsubscribe = onAuthStateChanged(auth, function (authState) {
      return subscriber.next(authState);
    });
    return {
      unsubscribe: unsubscribe
    };
  });
}
export function httpsCallable(name) {
  var callable = httpsCallableFire(fn, name);
  return function (data) {
    return from(callable(data)).pipe(map(function (r) {
      return r.data;
    }));
  };
}
export var refreshTokenFnRx = httpsCallable('refreshToken');