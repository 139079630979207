import { combineLatest, Observable, of, from, interval } from 'rxjs';
import { switchMap, pluck, shareReplay, map, startWith, catchError, repeatWhen, flatMap } from 'rxjs/operators';
import { captureException } from '@sentry/nextjs';
import { log } from '../utils/rxLog';
import { User, getIdToken, getIdTokenResult } from 'firebase/auth';
import { refreshTokenFnRx, userObs, authState } from './rxFirebase';

type StateTypes = 'in' | 'out';
export type AuthState = StateTypes | 'loading';

const getUserWithClaims = (user: User): Observable<User> =>
  from(getIdToken(user)).pipe(
    switchMap(() => from(getIdTokenResult(user))),
    pluck('claims'),
    // log('Claims :'),
    switchMap((claims) =>
      claims['https://hasura.io/jwt/claims']
        ? of(user)
        : refreshTokenFnRx({}).pipe(
            // log('refreshed claims'),
            switchMap(() => from(getIdToken(user, true))),
            switchMap(() => of(user)),
          ),
    ),
  );

const repeatInterval$ = interval(4 * 60 * 1000);
export const userWithCorrectToken$ = userObs().pipe(
  repeatWhen((_) => repeatInterval$),
  switchMap((user) => (user ? getUserWithClaims(user) : of(undefined))),
  catchError((err) => {
    captureException(err, {
      extra: {
        source: 'rxjs@repeatInterval$',
      },
    });
    return of(null);
  }),
  shareReplay({
    bufferSize: 1,
    refCount: true,
  }),
);

export const correctToken$ = userWithCorrectToken$.pipe(
  catchError((err) => {
    captureException(err, {
      extra: {
        source: 'rxjs@correctToken$',
      },
    });
    return of(null);
  }),
  switchMap((user) => (user ? from(user.getIdToken()) : of(undefined))),
);

export const authState$: Observable<AuthState> = combineLatest([
  authState().pipe(map((value) => (value ? { loading: true } : { loading: false }))),
  userWithCorrectToken$.pipe(
    startWith(undefined),
    switchMap((user) =>
      user
        ? of(user).pipe(
            startWith(of({ value: user, loading: true })),
            map((value) => ({ value, loading: false })),
          )
        : of({ loading: false, value: null }),
    ),
  ),
]).pipe(
  // log('pre result'),
  map(([authStateRaw, withToken]: any) => {
    if (withToken?.value) {
      return 'in';
    }
    if (authStateRaw.loading && !withToken.value) {
      return 'loading';
    }
    return 'out';
  }),
  catchError((err) => {
    captureException(err, {
      extra: {
        source: 'rxjs@authState$',
      },
    });
    return of('out' as const);
  }),
  // log('result'),
  shareReplay({
    bufferSize: 1,
    refCount: true,
  }),
);

type PossibleRoles = 'user' | 'moderator';

interface HasuraExtraClaims {
  'x-hasura-default-role': PossibleRoles;
  'x-hasura-allowed-roles': PossibleRoles[];
  'x-hasura-user-id': string;
  'x-hasura-firebase-id': string;
}

const extractKeyFromClaim = <T>(key: keyof HasuraExtraClaims): Observable<T | undefined> =>
  userWithCorrectToken$.pipe(
    switchMap((user) =>
      user
        ? from(getIdTokenResult(user)).pipe(
            map((data) => {
              const claims = data.claims;
              if (claims['https://hasura.io/jwt/claims']) {
                console.log(claims);
                const hasuraClaims = claims['https://hasura.io/jwt/claims'] as HasuraExtraClaims;
                return (hasuraClaims?.[key] as any) as T;
              }
              return undefined;
            }),
          )
        : of(undefined),
    ),
    log(`User token payload ${key}`),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

export const userRole$ = extractKeyFromClaim<PossibleRoles>('x-hasura-default-role');

export const userAllowedRole$ = extractKeyFromClaim<PossibleRoles[]>('x-hasura-allowed-roles');

userRole$.subscribe(console.log);
