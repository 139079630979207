import React from 'react';

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M14.2 1.8c-.4-.4-1-.4-1.4 0L8 6.6 3.2 1.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L6.6 8l-4.8 4.8c-.4.4-.4 1 0 1.4s1 .4 1.4 0L8 9.4l4.8 4.8c.4.4 1 .4 1.4 0s.4-1 0-1.4L9.4 8l4.8-4.8c.4-.4.4-1 0-1.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClose;
